import { Chat } from "@lobby/core/entities/chat";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";

import { useMobile } from "@shared/lib";

import {
  ConversationWindow,
  ExpandWindowBtn,
  MinimizeWindowBtn,
  SendMessageBtn,
} from "./components";

import type { ChangeEvent, KeyboardEvent } from "react";

interface ISupportChatWindowProps {
  onMinimized: VoidFunction;
}

const MAX_MESSAGE_LENGTH = 4096;

export function SupportChartWindow({ onMinimized }: ISupportChatWindowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isMobile = useMobile();
  const { $t } = useTranslate();

  const { data: conversation, refetch: refetchConversationList } = Chat.useConversationList();
  const { isPending, variables, mutate } = Chat.useSendMessage();

  const pendingMessage = isPending ? variables?.text : undefined;
  const conversationId = Array.isArray(conversation) ? conversation[0]?.id : undefined;

  const isOverflowLimitMessageLength = useMemo(
    () => inputMessage.length >= MAX_MESSAGE_LENGTH,
    [inputMessage],
  );

  function clearInputMessage() {
    setInputMessage("");
  }

  function prepareMessage(message: string) {
    const computedMessage = message.trim();

    if (isOverflowLimitMessageLength) {
      throw new Error("Message is too long");
    }

    return computedMessage;
  }

  function sendMessage(message: string) {
    mutate(
      { conversationId, text: message },
      {
        onSettled: () => {
          if (conversationId === undefined) {
            refetchConversationList();
          }
        },
      },
    );
  }

  function processInputMessage() {
    const message = prepareMessage(inputMessage);
    if (message) {
      sendMessage(message);
      clearInputMessage();
    }
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputMessage(e.target.value);
  }

  function handleSendMessageBtnClick() {
    processInputMessage();
  }

  function handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      processInputMessage();
    }
  }

  useEffect(() => {
    !isInputDisabled && inputRef.current?.focus();
  }, [isInputDisabled]);

  useEffect(() => {
    if (isMessagesLoaded && !isPending && !isErrorVisible) {
      setIsInputDisabled(false);
    } else {
      setIsInputDisabled(true);
    }
  }, [isMessagesLoaded, isPending, isErrorVisible]);

  return (
    <div
      className={clsx("support-chat-window", {
        "support-chat-window_expanded": isExpanded,
        "support-chat-window_minimized": isMinimized,
        "modal-dialog": isMobile && !isMinimized,
      })}
      onAnimationEnd={({ animationName }) => {
        animationName === "minimize-chat-window" && onMinimized();
      }}
    >
      {isMinimized ? (
        <div className="bg-athens-gray rounded-inherit absolute inset-0 dark:bg-bright-gray" />
      ) : (
        <div className="flex flex-col gap-2 h-full">
          <div className="flex items-end justify-between h-4 mobile-only:p-2 mobile-only:pb-0 mobile-only:min-h-6">
            <MinimizeWindowBtn onClick={() => setIsMinimized(true)} />
            <ExpandWindowBtn
              className="mobile-only:hidden"
              onClick={() => setIsExpanded((v) => !v)}
            />
          </div>
          <div className="flex flex-col grow min-h-0">
            <ConversationWindow
              conversationId={conversationId}
              pendingMessage={pendingMessage}
              isChatWindowExpanded={isExpanded} // Required to scroll to bottom when chat window is expanded
              onError={setIsErrorVisible}
              onMessagesLoading={setIsMessagesLoaded}
            />
            {isOverflowLimitMessageLength && (
              <span className="relative text-radical-red">
                {$t({ defaultMessage: "Message is too long" })}
              </span>
            )}
            <div className="bg-white rounded-b-rounded border-1 border-t-0 border-mercury relative h-10.5 min-h-10.5 after:absolute after:left-0 after:top-0 after:w-full after:h-[0.0625rem] after:bg-nepal dark:bg-outer-space dark:border-transparent dark:after:bg-blue-bayoux">
              <input
                className="bg-white size-full pl-2.5 pr-9 placeholder:text-nepal focus:outline-0 dark:bg-outer-space dark:placeholder:text-blue-bayoux"
                ref={inputRef}
                type="text"
                autoFocus
                value={inputMessage}
                placeholder={$t({ defaultMessage: "Type your message here..." })}
                autoComplete="off"
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                disabled={isInputDisabled}
              />
              <SendMessageBtn
                disabled={isInputDisabled || isOverflowLimitMessageLength}
                onClick={handleSendMessageBtnClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
